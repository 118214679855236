import { gql } from '@apollo/client';
import {
  UserFragment,
  CURRENCY_FRAGMENT,
  ShipmentFragment,
  OrderFragment,
  PaymentFragment,
} from './fragments';

export const SIGN_IN = gql`
  mutation SIGN_IN($data: SignInInput!) {
    signIn(data: $data) {
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`;

export const SIGN_S3 = gql`
  mutation SIGN_S3($data: SignS3Input!) {
    signS3(data: $data) {
      signedRequest
      url
    }
  }
`;

export const SIGN_UP = gql`
  mutation SIGN_UP($data: SignUpInput!) {
    signUp(data: $data) {
      user {
        ...UserFragment
      }
      token
    }
  }
  ${UserFragment}
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER(
    $record: UpdateOneUserInput!
    $filter: FilterUpdateOneUserInput!
  ) {
    updateUser(record: $record, filter: $filter) {
      record {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`;

export const UPDATE_CURRENCY = gql`
  mutation UPDATE_CURRENCY(
    $record: UpdateOneCurrencyInput!
    $filter: FilterUpdateOneCurrencyInput
  ) {
    updateCurrency(record: $record, filter: $filter) {
      record {
        ...CurrencyFragment
      }
    }
  }
  ${CURRENCY_FRAGMENT}
`;

export const CREATE_CURRENCY = gql`
  mutation CREATE_CURRENCY($record: CreateOneCurrencyInput!) {
    createCurrency(record: $record) {
      record {
        ...CurrencyFragment
      }
    }
  }
  ${CURRENCY_FRAGMENT}
`;

export const CREATE_SHIPMENT = gql`
  mutation CREATE_SHIPMENT($record: CreateOneShipmentInput!) {
    createShipment(record: $record) {
      record {
        ...ShipmentFragment
      }
    }
  }
  ${ShipmentFragment}
`;

export const CREATE_ORDER = gql`
  mutation CREATE_ORDER($data: CreateOrderInput!) {
    createOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

export const CREATE_USER = gql`
  mutation CREATE_USER($data: CreateOneUserInput!) {
    createUser(data: $data) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signOut {
      success
    }
  }
`;

export const NEW_SHIPMENT_EMAIL = gql`
  mutation NEW_SHIPMENT_EMAIL($data: NewShipmentEmailInput!) {
    newShipmentEmail(data: $data) {
      success
      err
    }
  }
`;

export const CREATE_PAYMENT = gql`
  mutation CREATE_PAYMENT($data: CreatePaymentInput) {
    createPayment(data: $data) {
      ...PaymentFragment
    }
  }
  ${PaymentFragment}
`;

export const CREATE_PAYMENT_INTENT_STRIPE = gql`
  mutation CREATE_PAYMENT_INTENT_STRIPE($data: CreatePaymentIntentStripeInput) {
    createPaymentIntentStripe(data: $data) {
      clientSecret
      payments {
        ...PaymentFragment
      }
    }
  }
  ${PaymentFragment}
`;

export const UPDATE_FREIGHT = gql`
  mutation UPDATE_FREIGHT($data: UpdateFreightInput!) {
    updateFreight(data: $data) {
      _id
      code
    }
  }
`;

export const UPDATE_LOAD = gql`
  mutation UPDATE_LOAD($data: UpdateLoadInput!) {
    updateLoad(data: $data) {
      _id
      code
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation UPDATE_ORDER($data: UpdateOrderInput!) {
    updateOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($data: ResetPasswordInput) {
    resetPassword(data: $data) {
      success
      err
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation CHANGE_PASSWORD($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      success
      err
    }
  }
`;

// user onboarding
export const USER_ONBOARDING = gql`
  mutation Mutation($data: UpdateUserInput) {
    userOnboarding(data: $data) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const UPDATE_PAYMENT_STATUS = gql`
  mutation UPDATE_PAYMENT_STATUS($data: UpdatePaymentStatusInput!) {
    updatePaymentStatus(data: $data) {
      ...PaymentFragment
    }
  }
  ${PaymentFragment}
`;

export const CREATE_SUGGESTION = gql`
  mutation CREATE_SUGGESTION($record: CreateOneSuggestionInput!) {
    createSuggestion(record: $record) {
      record {
        title
        description
      }
    }
  }
`;
